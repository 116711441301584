import Vue from 'vue'
import App from './App.vue'
import Button from 'ant-design-vue/lib/button';
import layout from 'ant-design-vue/lib/layout';
import row from 'ant-design-vue/lib/row';
import col from 'ant-design-vue/lib/col';
import menu from 'ant-design-vue/lib/menu';  
import tabs from 'ant-design-vue/lib/tabs';
import icon from 'ant-design-vue/lib/icon';
import card from 'ant-design-vue/lib/card';
import carousel from 'ant-design-vue/lib/carousel'
import affix from 'ant-design-vue/lib/affix'
import popover from 'ant-design-vue/lib/popover'
import modal from 'ant-design-vue/lib/modal'
import select from 'ant-design-vue/lib/select'
import radio from 'ant-design-vue/lib/radio'
import form from 'ant-design-vue/lib/form'
import 'ant-design-vue/dist/antd.css';
import spin from 'ant-design-vue/lib/spin'
import input from 'ant-design-vue/lib/input'
import router  from './router';
import 'ant-design-vue/lib/form/style/css';


Vue.component(input.name, input);
Vue.component(spin.name, spin);
Vue.component(form.Item.name, form.Item);
Vue.component(form.name, form);
Vue.component(modal.name, modal);
Vue.component(popover.name, popover);
Vue.component(affix.name, affix);
Vue.component(carousel.name, carousel);
Vue.component(card.name, card);
Vue.component(radio.name, radio);
Vue.component(radio.Group.name, radio.Group);
Vue.component(select.name, select);
Vue.component(select.Option.name, select.Option);
Vue.component(tabs.TabPane.name, tabs.TabPane);
Vue.component(tabs.TabContent.name, tabs.TabContent);
Vue.component(tabs.name, tabs);
Vue.component(row.name, row);
Vue.component(col.name, col);
Vue.component(menu.name, menu);
Vue.component(icon.name, icon);
Vue.component(menu.Item.name, menu.Item);
Vue.component(Button.name, Button);
Vue.component(layout.name, layout);
Vue.component(layout.Header.name, layout.Header);
Vue.component(layout.Footer.name, layout.Footer);
Vue.component(layout.Content.name, layout.Content);
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  mounted(){
    document.dispatchEvent(new Event('render-event'))
  },
  router
}).$mount('#app')
