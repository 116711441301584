<template>
  <div style="background-color: white;">
    <a-affix :offset-top="200" style="position:absolute;right:0;">
      <div class="toolbar">
        <a-row style="padding-top:20px;">
          <img style="width:72px;height:72px;" class="person" src="@/assets/vip-kf2.png">
        </a-row>
        <a-row style="margin-top:10px;">
          <a-icon type="phone"></a-icon>
          <span style="color:white;margin-left:4px;">热线电话</span>
        </a-row>
        <a-row>
          <span class="phone">13063922536</span>
        </a-row>
        <a-row style="margin-top:10px;">
          <a-button style="color: #fa541c;  borderColor: #fa541c;">
            <a :href="isMobile?'tencent://message/?uin=1379160449&Site=Sambow&Menu=yes':'http://wpa.qq.com/msgrd?v=3&uin=1379160449&site=qq&menu=yes'">QQ咨询</a>
          </a-button>
        </a-row>
        <a-row style="margin-top:10px;padding-bottom:20px;">
          <a-popover trigger="click" placement="left">
            <div slot="content">
              <img src="@/assets/qrcode.png" style="height:144px;width:144px;" />
            </div>
            <a-button style="color: #fa541c;  borderColor: #fa541c;">微信咨询</a-button>
          </a-popover>

        </a-row>

      </div>
    </a-affix>
    <a-row class="banner" :style="{paddingLeft:isMobile?'20px':'350px',paddingRight:isMobile?'20px':'350px','height':'440px'}">
      <a-col :xs="24" :md="12">
        <div :style="{textAlign:'left',paddingTop:'80px',color:'white','height':'440px'}">
          <span style="font-size:20px">星云教务培训管理系统</span>
          <br>
          <br>
          <span style="font-size:40px">安全、专业、保障</span>
          <br>
          <br>
          <span style="font-size:16px"> 数字化学校运营管理系统，专注解决培训学校经营管理难题</span>
          <br>
          <br>
          <br>
          <a-button style="border-color:#fa541c;color:#fa541c;" @click="$refs.freeTrial.show()">免费试用</a-button>
        </div>
      </a-col>
      <a-col :xs="0" :md="12">
        <img src="@/assets/小程序对接.png" style="height:440px;margin:0 auto;" />
      </a-col>
    </a-row>

    <a-row :style="{'margin-top':'80px',paddingLeft:isMobile?'20px':'350px',paddingRight:isMobile?'20px':'350px'}">
      <span style="font-size:34px;">青少年体适能培训机构一体化解决方案专家</span>
      <br>
      <br>
      <a-row :gutter="isMobile?12:32">
        <a-col :xs="12" :md="6">
          <div class="single-box">
            <div class="icon" style="background: #cdf1d8;">
              <a-icon type="user" style="color: #44ce6f;" />
            </div>
            <h3><a href="javascript:void (0)" style="color: #fa541c">学员信息管理</a></h3>
            <p>跟踪每一个客资，不浪费每一个辛苦而来的名单资源</p>
          </div>
        </a-col>
        <a-col :xs="12" :md="6">
          <div class="single-box">
            <div class="icon" style="background:  #fddcef;">
              <a-icon type="usergroup-add" style="color: #f78acb;" />
            </div>
            <h3><a href="javascript:void (0)" style="color: #fa541c">学员跟进管理</a></h3>
            <p>作为一名优秀的教育顾问，从不忘记和家长预约联系的时间</p>
          </div>
        </a-col>
        <a-col :xs="12" :md="6">
          <div class="single-box">
            <div class="icon" style="background: #edc3fc;">
              <a-icon type="schedule" style="color: #c679e3;" />
            </div>
            <h3><a href="javascript:void (0)" style="color: #fa541c">排课/课时管理</a></h3>
            <p>简单易用的排消课工具，让每一节课都承载着教与学的美好期许</p>
          </div>
        </a-col>
        <a-col :xs="12" :md="6">
          <div class="single-box">
            <div class="icon" style="background: #f9d2c4;">
              <a-icon type="cluster" style="color: #eb6b3d;" />
            </div>
            <h3><a href="javascript:void (0)" style="color: #fa541c">智能硬件</a></h3>
            <p>体测仪，智能手环深度集成，为学员提供科学有效的成长监测</p>
          </div>
        </a-col>

        <a-col :xs="12" :md="6">
          <div class="single-box">
            <div class="icon" style="background: #fddcef;">
              <a-icon type="user" style="color: #f78acb;" />
            </div>
            <h3><a href="javascript:void (0)" style="color: #fa541c">多角色协同</a></h3>
            <p>多角色协同合作，深度挖掘和优化每一个角色工作和细节</p>
          </div>
        </a-col>
        <a-col :xs="12" :md="6">
          <div class="single-box">
            <div class="icon" style="background:  #edc3fc;">
              <a-icon type="home" style="color: #c679e3;" />
            </div>
            <h3><a href="javascript:void (0)" style="color: #fa541c">家校联动</a></h3>
            <p>让家长深度参与到日常教学，为孩子的成长保驾护航</p>
          </div>
        </a-col>
        <a-col :xs="12" :md="6">
          <div class="single-box">
            <div class="icon" style="background: #f9d2c4;">
              <a-icon type="video-camera" style="color: #eb6b3d;" />
            </div>
            <h3><a href="javascript:void (0)" style="color: #fa541c">实时/历史监控</a></h3>
            <p>视频监控全程跟踪，实时直播/历史回看，保证每一节课可追溯</p>
          </div>
        </a-col>
        <a-col :xs="12" :md="6">
          <div class="single-box">
            <div class="icon" style="background: #cdf1d8;">
              <a-icon type="bar-chart" style="color: #44ce6f;" />
            </div>
            <h3><a href="javascript:void (0)" style="color: #fa541c"> 报表统计</a></h3>
            <p>多维度的校区统计报表，让机构调整和优化有据可依</p>
          </div>
        </a-col>
      </a-row>
    </a-row>

    <a-row :style="{'margin-top':'80px',paddingLeft:isMobile?'20px':'350px',paddingRight:isMobile?'20px':'350px'}">
      <span style="font-size:34px;">高效易用的体适能运营管理系统</span>
      <br>
      <br>
      <!-- //autoplay -->
      <a-carousel autoplay>
        <!-- <div slot="prevArrow" class="custom-slick-arrow" style="left: 10px;zIndex: 1">
          <a-icon type="left-circle" />
        </div>
        <div slot="nextArrow" class="custom-slick-arrow" style="right: 10px">
          <a-icon type="right-circle" />
        </div> -->
        <div>
          <a-row :gutter="48">
            <a-col :md="16" :xs="24">
              <img src="@/assets/kebiao.png" style="width:100%" />
            </a-col>
            <a-col :md="8" :xs="24">
              <a-row>
                <div style="text-align: left;">
                  <span style="font-size: 26px;font-weight: bold;">排课系统</span>
                  <br>
                  <span style="font-size: 16px;">班级、课程管理精细化，教务运营更高效</span>
                </div>
                <br>
                <a-row>
                  <a-col :span="12">
                    <div style="text-align: left;">
                      <span style="font-size: 16px;color: #676c70;">图形化智能排课</span>
                      <br>
                      <span style="font-size: 14px; color: #8d9399;">自动检测排课冲突，轻松解决排课烦恼</span>
                    </div>
                  </a-col>
                  <a-col :span="12">
                    <div style="text-align: left;">
                      <span style="font-size: 16px;color: #676c70;">手机端调班调课</span>
                      <br>
                      <span style="font-size: 14px; color: #8d9399;">调班调课一键操作，在线审批省时省力</span>
                    </div>
                  </a-col>
                </a-row>
                <br>
                <a-row>
                  <a-col :span="12">
                    <div style="text-align: left;">
                      <span style="font-size: 16px;color: #676c70;">课程管理</span>
                      <br>
                      <span style="font-size: 14px; color: #8d9399;">标准上课管理，规避课程难题</span>
                    </div>
                  </a-col>
                  <a-col :span="12">
                    <div style="text-align: left;">
                      <span style="font-size: 16px;color: #676c70;">班级管理</span>
                      <br>
                      <span style="font-size: 14px; color: #8d9399;">建班、分班灵活操作，多场景批量管理</span>
                    </div>
                  </a-col>
                </a-row>
                <br>
                <a-row>
                  <a-col :span="12">
                    <div style="text-align: left;">
                      <span style="font-size: 16px;color: #676c70;">补课</span>
                      <br>
                      <span style="font-size: 14px; color: #8d9399;">在线安排缺勤补课，即时撤销灵活便捷</span>
                    </div>
                  </a-col>
                  <a-col :span="12">
                    <div style="text-align: left;">
                      <span style="font-size: 16px;color: #676c70;">请假</span>
                      <br>
                      <span style="font-size: 14px; color: #8d9399;">在线请假审批，节省沟通成本</span>
                    </div>
                  </a-col>
                </a-row>
              </a-row>
            </a-col>
          </a-row>
        </div>
        <div>
          <a-row :gutter="48">
            <a-col :md="16" :xs="24">
              <a-row :gutter="48">
                <a-col :xs=12>
                  <img src="@/assets/shouji1.jpg" :style="{height:(isMobile?'auto':'450px'),width:(isMobile?'100%':'auto'),'margin-left':'auto'}" />
                </a-col>
                <a-col :xs=12>
                  <img src="@/assets/shouji2.jpg" :style="{height:(isMobile?'auto':'450px'),width:(isMobile?'100%':'auto')}" />
                </a-col>
              </a-row>
            </a-col>
            <a-col :md="8" :xs="24">
              <a-row>
                <div style="text-align: left;">
                  <span style="font-size: 26px;font-weight: bold;">家校互联</span>
                  <br>
                  <span style="font-size: 16px;">家长可以通过微信公众号查看学员课程安排，上课情况，实时直播，以及对课堂进行评价</span>
                </div>
                <br>
                <a-row>
                  <a-col>
                    <div style="text-align: left;">
                      <span style="font-size: 16px;color: #676c70;">学员通知</span>
                      <br>
                      <span style="font-size: 14px; color: #8d9399;">机构可以通过微信公众号对学员进行通知下发，上课提醒、完课通知、学习报告、学生请假、停课通知等等</span>
                    </div>
                  </a-col>
                </a-row>
                <br>
                <a-row>
                  <a-col>
                    <div style="text-align: left;">
                      <span style="font-size: 16px;color: #676c70;">老师通知通知</span>
                      <br>
                      <span style="font-size: 14px; color: #8d9399;">机构可以通过微信公众号对上课老师进行通知下发，上课提醒、停课通知、请假通知、开班通知、时间调整等等</span>
                    </div>
                  </a-col>
                </a-row>
                <br>
                <a-row>
                  <a-col>
                    <div style="text-align: left;">
                      <span style="font-size: 16px;color: #676c70;">课程实时直播</span>
                      <br>
                      <span style="font-size: 14px; color: #8d9399;">家长可在公众号中查看学员当前课时的实时直播，监督课程执行情况</span>
                    </div>
                  </a-col>
                </a-row>
                <br>
                <a-row>
                  <a-col>
                    <div style="text-align: left;">
                      <span style="font-size: 16px;color: #676c70;">课程评价</span>
                      <br>
                      <span style="font-size: 14px; color: #8d9399;">家长可在公众号中对每节课进行评价打分，通过家长反馈促使机构优化服务流程和上课质量</span>
                    </div>
                  </a-col>
                </a-row>
              </a-row>
            </a-col>
          </a-row>
        </div>
        <div>
          <a-row :gutter="48">
            <a-col :md="16" :xs="24">
              <img src="@/assets/jiaoan.png" style="width:100%;" />

            </a-col>
            <a-col :md="8" :xs="24">
              <a-row>
                <div style="text-align: left;">
                  <span style="font-size: 26px;font-weight: bold;">教案管理</span>
                  <br>
                  <span style="font-size: 16px;">机构设计完善的课程教案，保证教学质量，提高学员训练效果</span>
                </div>
                <br>
                <a-row>
                  <a-col>
                    <div style="text-align: left;">
                      <span style="font-size: 16px;color: #676c70;">教案设计</span>
                      <br>
                      <span style="font-size: 14px; color: #8d9399;">督促教练认真钻研业务，设计有效的课程体系，从而保证课程体系的标准化执行，提高学员的训练效果和用户体验</span>
                    </div>
                  </a-col>
                </a-row>
                <br>
                <a-row>
                  <a-col>
                    <div style="text-align: left;">
                      <span style="font-size: 16px;color: #676c70;">大屏播放课程教案</span>
                      <br>
                      <span style="font-size: 14px; color: #8d9399;">结合教室内大屏进行教案的播放，让教学内容清晰透明，规范教练的上课内容，展现机构的专业性</span>
                    </div>
                  </a-col>
                </a-row>
                <br>
                <a-row>
                  <a-col>
                    <div style="text-align: left;">
                      <span style="font-size: 16px;color: #676c70;">定期评审教案</span>
                      <br>
                      <span style="font-size: 14px; color: #8d9399;">定期回顾所有教案设计，评审并规范每节课时的上课内容以督促教练提高业务能力</span>
                    </div>
                  </a-col>
                </a-row>
              </a-row>
            </a-col>
          </a-row>
        </div>
        <div>
          <a-row :gutter="48">
            <a-col :md="16" :xs="24">
              <img src="@/assets/zhibo.png" style="width:100%;margin-right:40px;margin-bottom: 40px;" />
              <img src="@/assets/zhibo2.jpg" :style="{'position': 'absolute',right: 5,bottom: 0, width:isMobile?'100px':'200px'}" />
            </a-col>
            <a-col :md="8" :xs="24">
              <a-row>
                <div style="text-align: left;">
                  <span style="font-size: 26px;font-weight: bold;">视频监控</span>
                  <br>
                  <span style="font-size: 16px;">课程实时监控，保证教学准确及学员训练安全</span>
                </div>
                <br>
                <a-row>
                  <a-col>
                    <div style="text-align: left;">
                      <span style="font-size: 16px;color: #676c70;">课程实时直播</span>
                      <br>
                      <span style="font-size: 14px; color: #8d9399;">家长可在手机端实时查看学员上课情况，监督教学质量</span>
                    </div>
                  </a-col>
                </a-row>
                <br>
                <a-row>

                  <a-col>
                    <div style="text-align: left;">
                      <span style="font-size: 16px;color: #676c70;">课程录像回放</span>
                      <br>
                      <span style="font-size: 14px; color: #8d9399;">课程录像可随时回放，定时复盘教学情况加强教学质量，规避教学风险</span>
                    </div>
                  </a-col>
                </a-row>

              </a-row>
            </a-col>
          </a-row>
        </div>
        <div>
          <a-row :gutter="48">
            <a-col :xs=24 :md="16">
              <img src="@/assets/tice.jpg" style="width:100%" />
              <!-- <img src="@/assets/shouhuan.jpg" v-if="!isMobile" :style="{position: 'absolute', left:'20px',top:'20px',height:'180px'}" />
              <img src="@/assets/shouhuan2.jpg" v-if="!isMobile" style="position: absolute; left:20px;top:220px;height:180px;" /> -->
            </a-col>
            <a-col :xs=24 :md="8">
              <a-row>
                <div style="text-align: left;">
                  <span style="font-size: 26px;font-weight: bold;">智能硬件</span>
                  <br>
                  <span style="font-size: 16px;">深度集成各种智能硬件，为了学员科学训练保驾护航</span>
                </div>
                <br>
                <a-row>
                  <a-col :span="24">
                    <div style="text-align: left;">
                      <span style="font-size: 16px;color: #676c70;">体测仪</span>
                      <br>
                      <span style="font-size: 14px; color: #8d9399;">定期体测，科学跟踪学员成长发展，针对性提供训练指导</span>
                    </div>
                  </a-col>
                </a-row>
                <br>
                <a-row>
                  <a-col :span="24">
                    <div style="text-align: left;">
                      <span style="font-size: 16px;color: #676c70;">智能手环</span>
                      <br>
                      <span style="font-size: 14px; color: #8d9399;">全程监测学员身体变化，保证学员安全训练</span>
                    </div>
                  </a-col>
                </a-row>

              </a-row>
            </a-col>
          </a-row>
        </div>
      </a-carousel>
    </a-row>

    <a-row :style="{'margin-top':'80px',paddingLeft:isMobile?'20px':'350px',paddingRight:isMobile?'20px':'350px'}">
      <span style="font-size:34px;">服务套餐</span>
      <br>
      <br>
      <a-row :gutter="48">

        <a-col :xs="24" :md="8" style="margin-top:5px;">
          <a-card title="基础版" :headStyle="{color:'#333',fontWeight:'bold'}">
            <!-- <a-row class="price">
              <span>¥3600/元/年</span>
            </a-row> -->
            <a-row class="priceItem">
              <span>学员管理</span>
            </a-row>
            <a-row class="priceItem">
              <span>学员跟进/预约</span>
            </a-row>
            <a-row class="priceItem">
              <span>订单/课时管理</span>
            </a-row>
            <a-row class="priceItem">
              <span>排课管理</span>
            </a-row>
            <a-row class="priceItem">
              <span>教务管理</span>
            </a-row>
            <a-row class="priceItem">
              <span>合同管理</span>
            </a-row>

            <a-row class="priceItem">
              <span>数据报表</span>
            </a-row>
            <a-row class="priceItem">
              <span>家校互联</span>
            </a-row>
          </a-card>
        </a-col>
        <a-col :xs="24" :md="8" style="margin-top:5px;">
          <a-card title="旗舰版" :headStyle="{color:'#333',fontWeight:'bold'}">
            <!-- <a-row class="price">
              <span>¥10000/元/年</span>
              <span style="font-size:16px;margin-left:4px;">起</span>
            </a-row> -->
            <a-row class="priceItem">
              <span>基础版功能</span>
            </a-row>
            <a-row class="priceItem">
              <span>教案管理</span>
            </a-row>
            <a-row class="priceItem">
              <span>目标管理</span>
            </a-row>
            <a-row class="priceItem">
              <span>体测管理</span>
            </a-row>
            <a-row class="priceItem">
              <span>库存/积分管理</span>
            </a-row>
            <a-row class="priceItem">
              <span>智能硬件接入</span>
            </a-row>
            <a-row class="priceItem">
              <span>实时监控直播</span>
            </a-row>
            <a-row class="priceItem">
              <span>课程录像回放</span>
            </a-row>

          </a-card>
        </a-col>

        <a-col :xs="24" :md="8" style="margin-top:5px;">
          <a-card title="定制版/私有云" :headStyle="{color:'#333',fontWeight:'bold'}">
            <!-- <a-row class="price">
              <span>按需报价/定制</span>
            </a-row> -->
            <a-row class="priceItem">
              <span>完整版本功能</span>
            </a-row>
            <a-row class="priceItem">
              <span>定制方案/套餐</span>
            </a-row>
            <a-row class="priceItem">
              <span>自持私有化独立部署</span>
            </a-row>
            <a-row class="priceItem">
              <span>支持定制功能</span>
            </a-row>
            <a-row class="priceItem">
              <span>按需同步更新优化</span>
            </a-row>
            <a-row class="priceItem">
              <span>监控路数自主控制</span>
            </a-row>
            <a-row class="priceItem">
              <span>账号数量自主控制</span>
            </a-row>
            <a-row class="priceItem">
              <span>存储容量自主控制</span>
            </a-row>
          </a-card>
        </a-col>
      </a-row>
    </a-row>
    <freeTrial ref="freeTrial"></freeTrial>
  </div>
</template>
<script>
import { deviceEnquire, DEVICE_TYPE } from '@/util/device'
import freeTrial from './freeTrial.vue'
export default {
  components: {
    freeTrial
  },
  data () {
    return {
      isMobile: false
    }
  },
  mounted () {
    deviceEnquire(deviceType => {
      switch (deviceType) {
        case DEVICE_TYPE.DESKTOP:

          break
        case DEVICE_TYPE.TABLET:

          break
        case DEVICE_TYPE.MOBILE:
        default:
          this.isMobile = true
          break
      }
    })
  },
  methods: {
    login () {
      window.open('http://fitness.zqwlkj.net', '_blank');
    }
  }
}
</script>
<style>
.banner {
  /* height: 440px; */
  background: linear-gradient(90deg, #fa541c 0, #b45738 100%);
}
li img {
  width: 150px;
}
li.one {
  position: absolute;
  left: 75px;
  top: 115px;
  list-style: none;
}
li.two {
  position: absolute;
  left: 290px;
  top: 25px;
  list-style: none;
}
li.three {
  position: absolute;
  left: 740px;
  top: 25px;
  list-style: none;
}
li.four {
  position: absolute;
  left: 965px;
  top: 115px;
  list-style: none;
}
li.five {
  position: absolute;
  left: 960px;
  top: 325px;
  list-style: none;
}
li.six {
  position: absolute;
  left: 710px;
  top: 410px;
  list-style: none;
}
li.seven {
  position: absolute;
  left: 330px;
  top: 410px;
  list-style: none;
}
li.eight {
  position: absolute;
  left: 100px;
  top: 320px;
  list-style: none;
}
.section-box {
  background: url('@/assets/product-0.png') no-repeat;
  background-size: cover;
}
.single-box .icon {
  display: inline-block;
  text-align: center;
  width: 55px;
  height: 55px;
  line-height: 55px;
  background: #cdf1d8;
  border-radius: 50%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 18px;
}
.fa,
.fab,
.fal,
.far,
.fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
.single-box {
  border: 1px dashed #cdf1d8;
  margin-bottom: 10px;
  padding: 30px 20px;
  position: relative;
  height: 205px;
  z-index: 1;
  border-radius: 5px;
  background: #ffffff;
  -webkit-box-shadow: 0 0 10px rgba(72, 69, 224, 0.1);
  box-shadow: 0 0 10px rgba(72, 69, 224, 0.1);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.ant-btn-primary {
  color: #fff;
  background-color: #fa541c;
  border-color: #fa541c;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.price {
  color: #fa541c;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
}
.priceItem {
  color: #666666;
  border-top: 1px dashed #eee;
  padding: 8px 0;
}
.item {
  color: #666666;
  border-top: 1px dashed #eee;
  padding: 8px 0;
}

.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 160px;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}

.toolbar {
  background-color: #fa541c;
  color: white;
  margin-right: 10px;
  border-radius: 100px 100px 4px 4px;
  width: 112px;
  height: 250px;
}
</style>
