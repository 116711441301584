<!--排课-->
<template>
  <a-modal :width="isMobile?'80%':'500px'" :dialogStyle="{borderRadius: 10}" :destroyOnClose="true" :visible="visible" :confirmLoading="confirmLoading" :footer="null" @cancel="handleCancel">
    <a-spin :spinning="confirmLoading">
      <div v-if="first">
        <a-row>
          <div style="font-size:26px;font-weight:bold;color:#3D4042;line-height:37px;margin-top:10px;margin-bottom:20px;">申请免费体验</div>
        </a-row>
        <a-form :form="form">
          <a-form-item label="机构名称" :labelCol="labelCol" :wrapperCol="wrapperCol" style="text-align:left;">
            <a-input placeholder="学校/机构名称" v-decorator="['name', {rules: [{required: true, message: '请输入学校/机构名称！'}]}]" />
          </a-form-item>
          <a-form-item label="机构类型" :labelCol="labelCol" :wrapperCol="wrapperCol" style="text-align:left;">
            <a-radio-group name="radioGroup" v-decorator="['type', {rules: [{required: true, message: '请选择机构类型！'}]}]">
              <a-radio :value="1">教培机构 </a-radio>
              <a-radio :value="2">体培机构 </a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="电话" :labelCol="labelCol" :wrapperCol="wrapperCol" style="text-align:left;">
            <a-input placeholder="联系电话" v-decorator="['phone', {rules: [{required: true, message: '请输入联系电话！'}]}]" />
          </a-form-item>
        </a-form>
        <a-button style="width:90%;margin-top:20px;margin-bottom:20px;backgroundColor: #fa541c;  borderColor: #fa541c; color:white;height:42px;font-size:16px;" @click="handleSubmit">免费试用</a-button>
      </div>
      <div v-else>
        <a-row>
          <img src="@/assets/form-success.png" style="height:78px;weight:78px;margin:89px auto 10px;">
        </a-row>
        <a-row>
          <div style="font-size:24px;font-weight:400;line-height:24px;margin-bottom:20px;color:#303133;">提交成功</div>
        </a-row>
        <a-row>
          <div style="height: 42px;
    font-size: 14px;
    font-weight: 400;
    color: #a1aab3;
    line-height: 21px;
    text-align: center;
    margin-bottom: 44px;">客户经理会在1个工作日内与您电话联系，请保持手机畅通</div>
        </a-row>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import moment from 'moment'
import Vue from 'vue'
import { deviceEnquire, DEVICE_TYPE } from '@/util/device'
import axios from 'axios'
Vue.prototype.$axios = axios

export default {
  components: {

  },
  data () {
    return {
      isMobile: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      form: this.$form.createForm(this),
      confirmLoading: false,
      visible: false,
      first: true,
    }
  },
  mounted () {
    deviceEnquire(deviceType => {
      switch (deviceType) {
        case DEVICE_TYPE.DESKTOP:

          break
        case DEVICE_TYPE.TABLET:

          break
        case DEVICE_TYPE.MOBILE:
        default:
          this.isMobile = true
          break
      }
    })
  },
  methods: {
    moment,
    // 初始化方法
    show () {
      this.visible = true
      this.first = true
    },
    /**
     * 提交表单
     */
    handleSubmit () {
      const { form: { validateFields } } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          for (const key in values) {
            if (typeof (values[key]) === 'object') {
              values[key] = JSON.stringify(values[key])
            }
            if (values[key] === 'null') {
              delete values[key]
            }
          }
          console.log(values)
          this.$axios.post('https://fitness.zqwlkj.net/api/Common/freeTrial', values).then(res => {
            console.log(res.data);
            this.first = false
          }, err => {
            console.log(err);
            this.first = false
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel () {
      this.form.resetFields()
      this.visible = false
    },

  }
}
</script>
<style>
.ant-modal-content {
  border-radius: 20px !important;
}
</style>