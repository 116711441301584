<template>
  <a-layout>
    <a-layout-header :style="{'background-color': 'white','padding':(isMobile?'0 20px':'0 50px')}">
      <img src="@/assets/logo.png" :style="{width:'32px',height:'32px','margin-top':'16px',float:'left','margin-left':!isMobile?'300px':'0'}">
      <span style="float:left;margin-left:15px; font-size: 26px;font-weight: bold;color:#fa541c">常州正奇网络科技有限公司-星云教务</span>
      <!-- <a-menu v-model="pages" mode="horizontal" style="float:left;background-color: transparent;margin-left:50px;margin-top:6px;border-bottom:0;">
        <a-menu-item key="1">
          <router-link to="/">首页</router-link>
        </a-menu-item>
        <a-menu-item key="2">
          <router-link to="/case">案例</router-link>
        </a-menu-item>
        <a-menu-item key="3">
          <router-link to="/about">关于我们</router-link>
        </a-menu-item>
      </a-menu> -->

      <!-- <a-button :style="{float:'right',marginTop:'10px','margin-right':!isMobile?'300px':'20px'}" type="primary">免费试用</a-button> -->
      <!-- <a-button :style="{'float':'right','margin-top':'15px','margin-right':!isMobile?'300px':'0',backgroundColor: '#fa541c',  borderColor: '#fa541c'}" @click="login" type="primary">立即登录</a-button> -->

    </a-layout-header>
    <a-layout-content style="height:100%;">
      <page1></page1>
    </a-layout-content>
    <a-layout-footer style="background-color: white;padding-top:40px;">
      <GlobalFooter></GlobalFooter>
    </a-layout-footer>
  </a-layout>
</template>
<script>

import GlobalFooter from '@/components/GlobalFooter.vue';
import { deviceEnquire, DEVICE_TYPE } from '@/util/device'

import page1 from '@/views/page1.vue'
export default {
  components: {
    GlobalFooter,
    page1,

  },
  data () {
    return {
      pages: ['1'],
      isMobile: false
    }
  },
  mounted () {
    deviceEnquire(deviceType => {
      switch (deviceType) {
        case DEVICE_TYPE.DESKTOP:
          break
        case DEVICE_TYPE.TABLET:
          break
        case DEVICE_TYPE.MOBILE:
        default:
          this.isMobile = true
          break
      }
    })
  },
  methods: {
    login () {
      window.open('http://fitness.zqwlkj.net', '_blank');
    }
  }
}
</script>
<style>
/* .ant-menu-item-selected {
  border-top: 0;
  border-bottom: 2px transparent;
} */
.ant-btn-primary {
  color: #fff;
  background-color: #fa541c;
  border-color: #fa541c;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
a {
  link-color: '#05AE44';
  color: '#05AE44';
}
</style>
