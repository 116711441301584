import Vue from 'vue'
import VueRouter from 'vue-router'
import page1 from '@/views/page1'
import page2 from '@/views/page2'
import page3 from '@/views/page3'
Vue.use(VueRouter) 


export default new VueRouter({
	mode: "history",
  routes: [              //配置路由，这里是个数组
    {                    //每一个链接都是一个对象
			path:'/',
			name:'page1',
			component:()=>page1,//import导入页面公共组件模块
    },
		{                    //每一个链接都是一个对象
			path:'/case',
			name:'page2',
			component:()=>page2,//import导入页面公共组件模块
    },
		{                    //每一个链接都是一个对象
			path:'/about',
			name:'page3',
			component:()=>page3,//import导入页面公共组件模块
    },
  ]
})

 
  